import { Clock } from "./Clock.js";
import { SETTINGS } from "./constants.js";
import { useGlobalClockTicker } from "./useTicker.js";

if (SETTINGS.AUTO_REFRESH_HOUR) {
  // Refresh every hour.
  setTimeout(() => {
    window.location.reload();
  }, 3600 * 1000);
}
if (SETTINGS.AUTO_REFRESH_MINUTE) {
  // Refresh every minute.
  setTimeout(() => {
    window.location.reload();
  }, 60 * 1000);
}

function App() {
  useGlobalClockTicker();
  return (
    <>
      <div className="flex-container">
        <Clock timezone="UTC" isUTC={true} />
      </div>
      <div className="flex-container">
        <Clock timezone="America/Vancouver" description="Canada/Vancouver" />
        <Clock timezone="America/Chicago" description="US/Huston" />
        <Clock timezone="America/New_York" description="US/New York" />
        <Clock timezone="America/Sao_Paulo" description="Brazil/Brasília" />
      </div>
      <div className="flex-container primary-clock">
        <Clock timezone="Europe/Oslo" description="Norway" />
      </div>
      <div className="flex-container">
        <Clock timezone="Asia/Qatar" description="Qatar/Doha" />
        <Clock timezone="Asia/Kolkata" description="India/Kolkata" />
        <Clock timezone="Australia/Perth" />
        <Clock timezone="Australia/Sydney" />
      </div>
    </>
  );
}

export default App;
