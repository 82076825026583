import { SETTINGS } from "./constants";
import { useGlobalClock } from "./useTicker";

const CLOCK_FORMAT = SETTINGS.SHOW_SECONDS ? "HH:mm:ss" : "HH:mm";
const CLOCK_FORMAT_UTC = SETTINGS.SHOW_SECONDS_UTC ? "HH:mm:ss" : "HH:mm";

const DATE_FORMAT = "dddd DD.MM.";
const DATE_FORMAT_UTC = "dddd | YYYY-MM-DD";

export const Clock = ({ timezone, isUTC, description }) => {
  const { clockFormat } = useGlobalClock(timezone);
  const ampm = clockFormat("A");
  const date = clockFormat(isUTC ? DATE_FORMAT_UTC : DATE_FORMAT);
  let gradient = "";
  if (!isUTC) {
    const hour = clockFormat("H");
    const blackFace = hour >= 8 && hour <= 15 ? "black-clock-face" : "";
    gradient = `${blackFace} g${hour}`;
  }
  return (
    <div className={`clockdate-wrapper ${gradient}`}>
      <div id="description">{description ?? timezone}</div>
      <div id="clock">
        <span>{clockFormat(isUTC ? CLOCK_FORMAT_UTC : CLOCK_FORMAT)}</span>
        <span className="ampm">{ampm}</span>
      </div>
      <div id="date">{date}</div>
    </div>
  );
};
