import moment from "moment-timezone";
import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";

const { useGlobalState } = createGlobalState({ clock: null });

export const useGlobalClockTicker = () => {
  const [clock, setClock] = useGlobalState("clock");

  useEffect(() => {
    const tick = () => setClock(moment.utc());
    const interval = setInterval(tick, 1000);
    tick();
    return () => clearInterval(interval);
  }, [setClock]);

  return {
    clock: clock,
  };
};

export const useGlobalClock = (tz) => {
  const [clock] = useGlobalState("clock");
  return {
    clock: clock,
    clockFormat: (format) => clock?.tz(tz).format(format),
  };
};
